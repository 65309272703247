import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
        <path
          d="M55,33 C50,30 44,28.5 37,28.5 C27,28.5 21,33.5 21,43.5 
          C21,53.5 27,58.5 37,58.5 C44,58.5 50,57 55,54 L55,48
          L37,48 L37,43 L60,43 L60,57 C55,60 46,63 37,63
          C23,63 16,55.5 16,43.5 C16,31.5 23,24 37,24
          C46,24 55,27 60,30 L55,33 Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
