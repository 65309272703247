import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(11.000000, 5.000000)">
        <path
          d="M55,33 C50,30 44,28.5 37,28.5 C27,28.5 21,33.5 21,43.5 
          C21,53.5 27,58.5 37,58.5 C44,58.5 50,57 55,54 L55,48
          L37,48 L37,43 L60,43 L60,57 C55,60 46,63 37,63
          C23,63 16,55.5 16,43.5 C16,31.5 23,24 37,24
          C46,24 55,27 60,30 L55,33 Z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
